/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent, .header-chapeau__crescent {
  position: relative;
}

.crescent.isafter::after, .isafter.header-chapeau__crescent::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before, .header-chapeau__crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before, .medium.header-chapeau__crescent::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before, .xlarge.header-chapeau__crescent::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before, .large.header-chapeau__crescent::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before, .medium.header-chapeau__crescent::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before, .small.header-chapeau__crescent::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before, .bottom-left.header-chapeau__crescent::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before, .top-left.header-chapeau__crescent::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before, .top-right.header-chapeau__crescent::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before, .bottom-right.header-chapeau__crescent::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent, .header-chapeau__crescent {
  position: relative;
}

.crescent.isafter::after, .isafter.header-chapeau__crescent::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before, .header-chapeau__crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before, .medium.header-chapeau__crescent::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before, .xlarge.header-chapeau__crescent::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before, .large.header-chapeau__crescent::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before, .medium.header-chapeau__crescent::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before, .small.header-chapeau__crescent::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before, .bottom-left.header-chapeau__crescent::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before, .top-left.header-chapeau__crescent::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before, .top-right.header-chapeau__crescent::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before, .bottom-right.header-chapeau__crescent::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

.widget-simu {
  position: relative;
  z-index: 5;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu {
    padding: 0 1rem;
  }
}
.widget-simu__container {
  padding: 2rem 2rem 4rem 2rem;
  width: 90%;
  max-width: 50rem;
  margin: 0 auto;
  border-radius: 1.5rem;
  border-top: 1px solid #196345;
  border-right: 1px solid #196345;
  border-bottom: 4px solid #196345;
  border-left: 1px solid #196345;
  background: #FFFFFF;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container {
    width: auto;
    padding: 2rem;
    bottom: -0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1.5rem;
  }
}
.widget-simu__container--title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

.widget-simu__container--title .main-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.25rem;
}

.widget-simu__container--title .main-title__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.26rem 0.52rem;
}

.widget-simu__container--title .main-title__element-heading {
  font-size: 1.25rem;
  font-weight: 700;
  color: #337F37;
  line-height: 1.75rem;
  letter-spacing: -0.025rem;
}

.widget-simu__container--title .main-title__element p {
  margin: unset;
}

.widget-simu__container--title .subtitle {
  max-width: 30rem;
  margin-bottom: 0.5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container--title .subtitle {
    margin-bottom: unset;
  }
}
.widget-simu__container--input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  gap: 0.75rem;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container--input {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.widget-simu__container--input .input-block {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container--input .input-block {
    display: none;
  }
}
.widget-simu__container--input .input-block__label {
  font-size: 1rem;
}

.widget-simu__container--input .input-block__input {
  position: relative;
}

.widget-simu__container--input .input-block__input--element {
  width: 100%;
  height: 3rem;
  border: 1px solid #6B7A8C;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  background: #FFFFFF;
  font-size: 1rem;
  color: #000000;
}

.widget-simu__container--input .input-block__input--element:focus, .widget-simu__container--input .input-block__input--element:focus-visible {
  outline: 1px solid #267B76;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.widget-simu__container--input .input-block__input--element:hover {
  border: 1px solid #154734;
}

.widget-simu__container--input .input-block__input--element.is-valid {
  border: 2px solid #3A913F;
  outline-color: #3A913F;
}

.widget-simu__container--input .input-block__input--element.is-disabled {
  color: #6B7A8C;
  border: 1px solid #D6DAE1;
  background: #EDEEF1;
}

.widget-simu__container--input .input-block__input--element.error {
  border: 2px solid #C8281E;
  outline-color: #C8281E;
}

.widget-simu__container--input .input-block__input--element.is-active {
  outline-width: 2px;
  outline-style: solid;
}

.widget-simu__container--input .input-block__input--element::-ms-input-placeholder { /* Edge */
  color: #6B7A8C;
  font-weight: 400;
  font-size: 1rem;
}

.widget-simu__container--input .input-block__input--element::-webkit-input-placeholder {
  color: #6B7A8C;
  font-weight: 400;
  font-size: 1rem;
  margin: auto 0;
}

.widget-simu__container--input .input-block__input--element::-moz-placeholder {
  color: #6B7A8C;
  font-weight: 400;
  font-size: 1rem;
  margin: auto 0;
}

.widget-simu__container--input .input-block__input--element:-ms-input-placeholder {
  color: #6B7A8C;
  font-weight: 400;
  font-size: 1rem;
  margin: auto 0;
}

.widget-simu__container--input .input-block__input--element::placeholder {
  color: #6B7A8C;
  font-weight: 400;
  font-size: 1rem;
  margin: auto 0;
}

.widget-simu__container--input .input-block__input--element::-webkit-inner-spin-button, .widget-simu__container--input .input-block__input--element::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.widget-simu__container--input .input-block__input--element[type=number] {
  -moz-appearance: textfield;
}

.widget-simu__container--input .input-block__input #input-symbol {
  font-size: 1rem;
  font-weight: 700;
  color: #6B7A8C;
  position: absolute;
  left: 2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-simu__container--input .input-block__input #input-symbol.is-active {
  color: #000;
}

.widget-simu__container--input .input-block__input #input-symbol.is-disabled {
  color: #6B7A8C;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.widget-simu__container--input .input-block__input .input-icons {
  position: absolute;
  right: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.widget-simu__container--input .input-block__input .input-icons .btn-cross,
.widget-simu__container--input .input-block__input .input-icons .i-check-circle-fill,
.widget-simu__container--input .input-block__input .input-icons .i-question-mark {
  display: none;
}

.widget-simu__container--input .input-block__input .input-icons .i-cross {
  font-size: 2rem;
  cursor: pointer;
}

.widget-simu__container--input .input-block__input .input-icons .input-icon {
  font-size: 1.5rem;
}

.widget-simu__container--input .input-block__input .input-icons .i-check-circle-fill {
  color: #3A913F;
}

.widget-simu__container--input .input-block__input .input-icons .i-question-mark {
  color: #C8281E;
}

.widget-simu__container--input .input-block__message {
  color: #C8281E;
  font-size: 0.875rem;
  position: absolute;
  bottom: -1.5rem;
}

.widget-simu__container--input .input-block__message--min, .widget-simu__container--input .input-block__message--max {
  display: none;
}

.widget-simu__container--input .input-button {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container--input .input-button {
    width: 100%;
  }
}
.widget-simu__container--input .input-button .btn {
  height: 3rem;
  padding: 0.5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .widget-simu__container--input .input-button .btn {
    font-size: 1rem;
    padding: 0.75rem;
  }
}
.header-chapeau {
  position: relative;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau.grid-container {
    padding: unset;
  }
}
.header-chapeau__crescent {
  position: unset;
}

.header-chapeau_container {
  height: 47rem;
  overflow: hidden;
  position: relative;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container {
    height: auto;
  }
}
.header-chapeau_container__headbloc {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 5;
  padding: 1.5rem 2rem;
  border-radius: 0 2rem 2rem 0;
  background-color: #FFFFFF;
  max-width: 43.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc {
    padding: 1rem;
    position: relative;
    top: unset;
    -webkit-transform: unset;
            transform: unset;
  }
}
.header-chapeau_container__headbloc.top-bloc {
  top: 4.125rem;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc.top-bloc {
    top: unset;
    -webkit-transform: unset;
            transform: unset;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc.header-chapeau__crescent::before, .header-chapeau_container__headbloc.header-chapeau__crescent::after {
    display: none;
  }
}
.header-chapeau_container__headbloc.header-chapeau__crescent::before {
  top: -4rem;
  left: 0;
}

.header-chapeau_container__headbloc.header-chapeau__crescent::after {
  width: 4rem;
  height: 4rem;
  bottom: -4rem;
  left: 0;
  scale: 1.04;
}

.header-chapeau_container__headbloc-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.header-chapeau_container__headbloc-title h1 > * {
  line-height: 3rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-title h1 > * {
    line-height: 2.125rem;
  }
}
.header-chapeau_container__headbloc-title_sub > p {
  font-size: 1.125rem;
  line-height: 1.624rem;
}

.header-chapeau_container__headbloc-title-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-title-button {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.header-chapeau_container__headbloc-title-button .btn {
  width: auto;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-title-button .btn {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-title-button.hide-mobile {
    display: none;
  }
}
.header-chapeau_container__headbloc-widgetsimu.desktop {
  display: none;
}

@media print, screen and (min-width: 48em) {
  .header-chapeau_container__headbloc-widgetsimu.desktop {
    display: block;
  }
}
.header-chapeau_container__headbloc-widgetsimu.mobile {
  display: none;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-widgetsimu.mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 1rem;
  }
}
.header-chapeau_container__headbloc-widgetsimu .widget-simu__container {
  position: relative;
  bottom: unset;
  left: unset;
  margin: unset;
  -webkit-transform: none;
          transform: none;
  width: auto;
  padding: 2rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__headbloc-widgetsimu .widget-simu__container {
    bottom: 3.5rem;
  }
}
.header-chapeau_container__second-btn {
  display: none;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__second-btn {
    display: block;
    position: absolute;
    z-index: 5;
    right: 1.5rem;
    bottom: 1.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__second-btn.hide-mobile {
    display: none;
  }
}
.header-chapeau_container__title {
  position: absolute;
  z-index: 5;
  right: 2rem;
  bottom: 2rem;
  background: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75rem 1.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 1.5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__title {
    position: absolute;
    bottom: unset;
    right: unset;
    background: none;
    padding: 0;
    border-radius: 0;
  }
}
.header-chapeau_container__title.header-chapeau__crescent::before {
  display: none;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__title.header-chapeau__crescent::before {
    display: block;
    top: 0;
  }
}
.header-chapeau_container__title.header-chapeau__crescent::after {
  display: none;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__title.header-chapeau__crescent::after {
    display: block;
    bottom: -0.75rem;
  }
}
.header-chapeau_container__title p {
  color: #000000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
  background: #FFFFFF;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__title p {
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0rem 1rem 1rem 0rem;
    max-width: 10rem;
  }
}
.header-chapeau_container__image {
  position: relative;
  overflow: hidden;
  height: 100%;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image {
    border-radius: 0 1.5rem 0 1.5rem;
  }
}
.header-chapeau_container__image__cover-label {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  z-index: 1;
  z-index: 5;
  top: 15%;
  right: 0;
}

.header-chapeau_container__image__cover-label.crescent::before, .header-chapeau_container__image__cover-label.header-chapeau__crescent::before {
  bottom: -1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label.crescent::before, .header-chapeau_container__image__cover-label.header-chapeau__crescent::before {
    bottom: -0.75rem;
  }
}
.header-chapeau_container__image__cover-label .label, .header-chapeau_container__image__cover-label .date {
  background: #FFFFFF;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;
  margin: unset;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label .label, .header-chapeau_container__image__cover-label .date {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
.header-chapeau_container__image__cover-label .label {
  border-radius: 1rem 0 0 1rem;
  padding: 0.75rem 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label .label {
    border-radius: 0.5rem 0 0 0.5rem;
    padding: 0.25rem 0.5rem;
  }
}
.header-chapeau_container__image__cover-label .label.crescent::before, .header-chapeau_container__image__cover-label .label.header-chapeau__crescent::before {
  top: -16px;
  right: 0.5px;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label .label.crescent::before, .header-chapeau_container__image__cover-label .label.header-chapeau__crescent::before {
    top: -0.75rem;
  }
}
.header-chapeau_container__image__cover-label .date {
  border-radius: 0 0 0 1rem;
  padding: 0 1rem 0.75rem 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label .date {
    border-radius: 0 0 0 0.5rem;
    padding: 0 0.5rem 0.25rem 0.5rem;
  }
}
.header-chapeau_container__image__cover-label .date.crescent::before, .header-chapeau_container__image__cover-label .date.header-chapeau__crescent::before {
  top: 0;
  left: -1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label .date.crescent::before, .header-chapeau_container__image__cover-label .date.header-chapeau__crescent::before {
    left: -0.75rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .header-chapeau_container__image__cover-label {
    top: 18%;
  }
}
.header-chapeau_container__image__cover-label .date.header-chapeau__crescent::before {
  left: 1.25rem;
  top: unset;
}

.header-chapeau_container__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.header-chapeau_container__image.border-radius-bottom {
  border-radius: 0rem 0rem 1.5rem 1.5rem;
}