/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
#go-to-top-button {
  display: none;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  padding-right: 1rem;
  opacity: 0;
  width: 100%;
  position: fixed;
  z-index: 1000;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}