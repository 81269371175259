/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sticky-button {
  padding: 0.75rem 0;
  opacity: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
  -webkit-box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media print, screen and (max-width: 47.99875em) {
  .sticky-button {
    padding: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .sticky-button-hiddenOnMobile {
    display: none;
  }
}
@media print, screen and (min-width: 48em) {
  .sticky-button-hiddenOnDesktop {
    display: none;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .sticky-button__container {
    width: 100%;
  }
  .sticky-button__container #sticky-simu-btn {
    width: 100%;
  }
}