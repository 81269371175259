/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent {
  position: relative;
}

.crescent.isafter::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

.banner-app {
  position: relative;
}

@media (max-width: 390px) {
  .banner-app.grid-container.fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
.banner-app__title {
  position: absolute;
  top: 3rem;
  z-index: 1;
}

@media (max-width: 768px) {
  .banner-app__title {
    top: 1.75rem;
  }
}
.banner-app__title::before {
  top: 6rem;
  left: 0;
}

@media print, screen and (max-width: 47.99875em) {
  .banner-app__title::before {
    top: 4.25rem;
    width: 1rem;
    height: 1rem;
  }
}
.banner-app__title span {
  display: block;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: -0.02rem;
  color: #000000;
  background-color: #FFFFFF;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media print, screen and (max-width: 47.99875em) {
  .banner-app__title span {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}
.banner-app__title--primary {
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-radius: 0rem 1.5rem 1.5rem 0rem;
}

.banner-app__title--primary::before {
  top: -2rem;
  left: 0;
}

@media print, screen and (max-width: 47.99875em) {
  .banner-app__title--primary::before {
    top: -1rem;
    width: 1rem;
    height: 1rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .banner-app__title--primary {
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0rem 1rem 1rem 0rem;
  }
}
.banner-app__title--secondary {
  padding: 0 1rem 0.5rem 1.5rem;
  border-bottom-right-radius: 1.5rem;
  margin-top: -0.5rem;
}

.banner-app__title--secondary::before {
  top: 0.5rem;
  right: -1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .banner-app__title--secondary::before {
    right: -0.45rem;
    width: 0.5rem;
    height: 0.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .banner-app__title--secondary {
    padding: 0rem 1rem 0.5rem 1rem;
    border-radius: 0rem 0rem 1rem 0rem;
  }
}
.banner-app__content {
  position: relative;
}

.banner-app__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .banner-app__btn.btn.default {
    padding: 0.75rem;
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.banner-app__btn.hide-desktop {
  display: none;
}

@media (max-width: 1024px) {
  .banner-app__btn.hide-desktop {
    display: block;
  }
}
@media (max-width: 1024px) {
  .banner-app__btn.hide-tablet {
    display: none;
  }
}
@media (max-width: 390px) {
  .banner-app__btn.hide-tablet {
    display: block;
  }
}
@media (max-width: 390px) {
  .banner-app__btn.hide-mobile {
    display: none;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .banner-app__picture--img {
    width: 100%;
  }
}