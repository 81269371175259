/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent {
  position: relative;
}

.crescent.isafter::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

.search-bar {
  margin: 1.5rem 0;
}

@media print, screen and (max-width: 47.99875em) {
  .search-bar {
    margin: 0.88rem 0 1.5rem 0;
  }
}
.search-bar__title {
  margin-bottom: 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .search-bar__title {
    margin-bottom: 0.875rem;
  }
}
.search-bar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
  margin: 0 auto;
}

.search-bar__container {
  position: relative;
  max-width: 36.8rem;
  width: 100%;
}

.search-bar__icon-search {
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
}

.search-bar__icon-search .icon {
  font-size: 1.5rem;
  color: #4B4F54;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.search-bar__icon-cross {
  display: none;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
}

.search-bar__icon-cross:hover {
  cursor: pointer;
}

.search-bar__icon-cross .icon {
  font-size: 2rem;
  color: #4B4F54;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.search-bar__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 0.25rem;
}

.search-bar__content .inbenta-autocomplete .autocomplete-list {
  width: 100%;
  position: absolute;
  top: 3.25rem;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.05), 1px 4px 8px -2px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.05), 1px 4px 8px -2px rgba(0, 0, 0, 0.1);
}

.search-bar__content .inbenta-autocomplete .autocomplete-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  border-bottom: 1px solid #DCDEE0;
  background: #FFFFFF;
}

.search-bar__content .inbenta-autocomplete .autocomplete-item:hover {
  background-color: #EFFAF4;
}

.search-bar__content .inbenta-autocomplete .autocomplete-item:first-child {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.search-bar__content .inbenta-autocomplete .autocomplete-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.search-bar__content .inbenta-autocomplete .autocomplete-item a {
  color: #4B4F54;
  min-height: 3rem;
  padding: 0.75rem 1rem;
  width: 100%;
  height: 100%;
}

.search-bar__input {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  padding: 0.75rem 2.75rem 0.75rem 2.75rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #6B7A8C;
  background: #FFFFFF;
  color: #4B4F54;
  margin-bottom: O;
}

.search-bar__input::-webkit-input-placeholder {
  overflow: hidden;
  color: #6B7A8C;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-bar__input::-moz-placeholder {
  overflow: hidden;
  color: #6B7A8C;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-bar__input:-ms-input-placeholder {
  overflow: hidden;
  color: #6B7A8C;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-bar__input::-ms-input-placeholder {
  overflow: hidden;
  color: #6B7A8C;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-bar__input::placeholder {
  overflow: hidden;
  color: #6B7A8C;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-bar__input:hover {
  border-color: #154734;
}

.search-bar__input:focus {
  outline: solid 2px #267B76;
}

.search-bar__input:disabled {
  border-color: #D6DAE1;
  background-color: #EDEEF1;
}

.search-bar__label {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  color: #4B4F54;
}

.search-bar__btn {
  max-height: 3rem;
  max-width: 3rem;
}

@media print, screen and (max-width: 47.99875em) {
  .search-bar__btn {
    display: none;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .search-bar__results {
    padding: 0 !important;
  }
}
.search-bar__results-title {
  font-size: 2rem;
  line-height: 2.5rem;
  color: #000000;
  margin: 3rem 0 2rem 0;
}

@media print, screen and (max-width: 47.99875em) {
  .search-bar__results-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 2rem 0 1.5rem 0;
  }
}
.search-bar__results-more {
  text-align: center;
}

.search-bar__results-more-btn {
  margin-top: 1.5rem;
  text-decoration: underline;
  color: #154734;
  cursor: pointer;
}

.search-bar__results .inbenta-faq-list-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 1rem;
  margin-left: 0;
}

.search-bar__results .inbenta-faq-list-item a {
  display: block;
  width: 100%;
  color: #000000;
  font-family: Ubuntu;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.015rem;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);
          box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
}

.search-bar__results .inbenta-faq-list-item a::after {
  content: "\e934";
  font-family: pf-icon;
  font-size: 1.5rem;
  color: #154734;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@media print, screen and (max-width: 47.99875em) {
  .search-bar__results .inbenta-faq-list-item a {
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: -0.0125rem;
  }
}
.search-bar__results .inbenta-faq-list-item--hidden {
  display: none;
}