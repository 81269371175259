/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.skip-link__container {
  position: relative;
}

@media print, screen and (max-width: 47.99875em) {
  .skip-link__container {
    display: none;
  }
}
.skip-link__container .skip-link {
  position: absolute;
  left: -99999rem;
  font-size: 1.3rem;
}

.skip-link__container .skip-link:focus {
  left: 0;
}